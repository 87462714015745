<template>
  <div class="page-data pt">
    <nav-bar ref="head" :showBack="true" :headerBack="newAppBack" />
    <swiper class="bg-swiper" :options="swipeOptionBg" ref="bgSwiper">
      <swiper-slide v-for="(item, index) in bgList" :key="index" :style="`background-image: url(${item})`">

      </swiper-slide>
    </swiper>
    <div class="title-box">
      <img class="title" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/t1.png" alt="">
      <p class="title-1 f32 fw6"># 获取你的2021运动数据</p>
    </div>
    <swiper class="data-swiper" :options="swipeOption" ref="dataSwiper">
      <swiper-slide class="col-between-center flex-none" v-if="yearData.ticket.status === '1'">
        <div class="f20 fw5" style="width: 100%; color: #FF6E00;">
          <p class="p1">过去的一年，我一共去了场馆<span class="p2 sw-1 f28 fw6">{{yearData.ticket.arrivalsCount}}</span>次，</p>
          <p class="p1"><span class="p2 sw-1 f28 fw6">{{yearData.ticket.maxDay}}</span>是我在馆最久的一天，</p>
          <p class="p1">专注运动，只为了让自己变得更好！</p><br>
          <p class="p1">新的一年，我要......</p>
        </div>
      </swiper-slide>
      <swiper-slide class="col-between-center flex-none" v-else>
        <div class="f20 fw5" style="width: 100%; color: #FF6E00;">
          <p class="p1">
            慢慢变好，是给自己最好的礼物，<br>
            还未在超鹿运动遇见你，<br>
            希望今年里能在场馆看到你，<br>
            挥洒汗水的身影。
          </p>
        </div>
      </swiper-slide>
      <swiper-slide class="col-between-center flex-none" v-if="yearData.course.status === '1'">
        <div class="f20 fw5" style="width: 100%; color: #018680;">
          <p class="p1">过去的一年，我累计上了<span class="p2 sw-2 f28 fw6">{{yearData.course.courseCount}}</span>节团课，</p>
          <p class="p1">累计消耗<span class="p2 sw-2 f28 fw6">{{yearData.course.courseCalorie}}</span>卡路里</p>
          <p class="p1">其中<span class="p2 sw-2 f28 fw6">{{yearData.course.favoriteCourseName}}</span>课程是我的最爱，</p>
          <p class="p1">一共约了<span class="p2 sw-2 f28 fw6">{{yearData.course.favoriteCourseCount}}</span>节</p><br>
          <p class="p1"><span class="p2 sw-2 f28 fw6">{{yearData.course.favoriteCourseTeacherName}}</span>是我约课最多的教练</p><br>
          <p class="p1">新的一年，我要......</p>
        </div>
      </swiper-slide>
      <swiper-slide class="col-between-center flex-none" v-else>
        <div class="f20 fw5" style="width: 100%; color: #018680;">
          <p class="p1">打磨过的身材，<br>
胜任各种衣服的剪裁，<br>
今年我要努力加练！</p>
        </div>
      </swiper-slide>
      <swiper-slide class="col-between-center flex-none" v-if="yearData.per.status === '1'">
        <div class="f22 fw5" style="width: 100%; color: #ED3E43;">
          <p class="p1">我一共完成 <span class="p2 sw-3 f28 fw6">{{yearData.per.perCount}}</span>节私教课</p>
          <p class="p1"><span class="p2 sw-3 f28 fw6">{{yearData.per.favoritePerTeacherName}}</span>是我的御用教练</p><br>
          <p class="p1">在蜕变的路上，</p>
          <p class="p1">超鹿私教一直陪伴和鼓励我</p><br>
          <p class="p1">新的一年，我要......</p>
        </div>
      </swiper-slide>
      <swiper-slide class="col-between-center flex-none" v-else>
        <div class="f22 fw5" style="width: 100%; color: #ED3E43;">
          <p class="p1">为了更多的<br>火锅、<br>烧烤、<br>奶茶…<br>是时候约一节私教啦！</p>
        </div>
      </swiper-slide>
      <swiper-slide class="col-between-center flex-none" v-if="yearData.camp.status === '1'">
        <div class="f22 fw5" style="width: 100%; color: #162572;">
          <p class="p1">过去的一年，我完成了</p>
          <p class="p1"><span class="p2 sw-4 f28 fw6">{{yearData.camp.campCount}}</span>期训练营</p>
          <p class="p1">对自我要求的不断精进</p><br>
          <p class="p1">让我变得更好</p><br>
          <p class="p1">新的一年，我要......</p>
        </div>
      </swiper-slide>
      <swiper-slide class="col-between-center flex-none" v-else>
        <div class="f22 fw5" style="width: 100%; color: #162572;">
          <p class="p1">放弃最轻松，<br>但坚持一定很酷！<br>渴望改变，就一起练！</p>
        </div>
      </swiper-slide>
    </swiper>
    <div class="cl-button cl-button--b f26 fw5 row-center-center" @click="goNext">新的一年，我要 >>></div>
  </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod'
import { getList } from './index'
import navBar from '@/components/nav-bar/nav-bar'
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    swiper,
    swiperSlide
  },
  data() {
    return {
      yearData: {
        per: {},
        ticket: {},
        course: {},
        camp: {},
      }, // 年度数据
      dataList: [1,2,3], // 数据列表
      bgList: [
        'https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/swiper-bg2.png',
        'https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/swiper-bg3.png',
        'https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/swiper-bg1.png',
        'https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/swiper-bg4.png',
      ], // 背景图列表
      swipeOptionBg: {
        effect: 'fade'
      },
      swipeOption: {
        effect: 'cards',
        slidesPerView: 'auto',
        centeredSlides: true,
        // loop: true,
        // initialSlide: 0,
        spaceBetween: 40,
        on:{
          transitionStart: (swiper) => {
            if (this.dataSwiper) {
              this.bgSwiper.slideTo(this.dataSwiper.activeIndex)
            }
          },
        },
      }
    }
  },
  computed: {
    bgSwiper() {
      return this.$refs.bgSwiper.swiper
    },
    dataSwiper() {
      return this.$refs.dataSwiper.swiper
    }
  },
  methods: {
    newAppBack,
    getData() {
      this.$axios.post(this.baseURLApp + '/summary/2021/detail', {
        token: this.token,
        userId: this.userId
      }).then((res) => {
        this.yearData = res.data
      })
    },
    goNext() {
      console.log(this.dataSwiper.activeIndex);
      this.$router.push(`page-share?swiper=${this.dataSwiper.activeIndex}`)
      this.countPoint('43','311','1487')
    },
  },
  async created() {
    sessionStorage.setItem('startPath',this.$route.path);
    await this.$getAllInfo()
    this.getData()
    this.countPoint('43','311','1486')
  },
}
</script>
<style lang="less">
@import url(./index.less);
.page-data {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  .data-swiper {
    width: 100%;
    height: 580px;
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }
    .swiper-slide {
      width: 473px;
      height: 405px;
      padding: 42px 36px;
      background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/swiper-word-bg.png);
      background-size: 100% 100%;
      transition: transform .5s;
      transform-origin: center;
      &.swiper-slide-active {
        transform: scale(1.312)
      }
    }
    .p1 {
      line-height: 40px;
    }
    .p2 {
      font-family: BebasNeueBold;
      padding: 4px 12px 0;
      border-radius: 6px;
      &.sw-1 {
        color: #FF6E00;
        background: #FFF69E;
      }
      &.sw-2 {
        color: #016F6A;
        background: #B9E6FF;
      }
      &.sw-3 {
        color: #E95455;
        background: #FFE4D9;
      }
      &.sw-4 {
        color: #152572;
        background: #CCCAFF;
      }
    }
  }
  .title-box {
    margin: 0 0 0 90px;
  }
  .title {
    width: 330px;
  }
  .bg-swiper {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .swiper-slide {
      background-size: 100% auto;
      background-position: bottom;
    }
  }

  .cl-button--b {
    position: fixed;
    left: 48px;
    bottom: 80px;
    width: 654px;
    height: 96px;
    color: #fff;
    background-color: #232831;
  }
}
</style>
